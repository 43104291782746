  * {
    font-family: 'Roboto', sans-serif !important;
  }

  body{
    margin: 0px;
    background-image: linear-gradient(to right top, #d8d8d8, #e8dae3, #ffdbdd, #ffe2ca, #fff0bc);

    background-size: cover;
    height:  100vh;
    width: 100%;
  }
  
  .container{
    width:  100%;
    height: 100vh;
    margin: 0px auto;
    padding: 0px;
  }

  .f{
    display : flex;
  }

  .f-jcc{
    justify-content: center;
  }

  .f-aic{
    align-items: center;
  }


  .card{
    width: 600px;
    background-color: #f4f4f4;
    border-radius : 10px;
    padding: 1.3em;
  }

  .t1{
    font-size: 2em;
    font-weight: 400;
    color:#514a60;
  }

  .t2{
    font-size: 1.3em;
  }

  .t3{
    font-size: 1.2em;
  }

  .t4{
    font-size: 1.1em;
  }

  .t5{
    font-size: 1em;
  }


  .mt1{
    margin-top: 1em;
  }
  .mt2{
    margin-top: 2em;
  }
  .mt3{
    margin-top: 3em;
  }

  .mb1{
    margin-bottom: 1em;
  }
  .mb2{
    margin-bottom: 2em;
  }
  .mb3{
    margin-bottom: 3em;
  }


  .mr1{
    margin-right: 1em;
  }
  .mr2{
    margin-right: 2em;
  }
  .mr3{
    margin-right: 3em;
  }

 


  .input-container{
    margin-bottom: 1.5em;
  }

  .input-container > label{
    font-size: 1.5em;
    margin-bottom: 1em;
    font-weight: 100;
  }

  .big-input{
    display: block;
    font-size:  1.3em;
    width: 100%;
    font-weight: 100;
    border: none;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .primary-bg{
    background-color: #005DD5;
  }

  .primary-bg:hover{
    background-color: #006FFF;
  }


  .secondary-bg{
    background-color: #00C853;
  }

  .secondary-bg:hover{
    background-color: #00DE5C;
  }

  

  .light-text{
    color: white;
  }

  .dark-text{
    color: white;
  }

  .big-btn{
    font-size: 1.3em;
    border: none;
    padding: 1em;
  }

  .full-btn{
    width: 100%;
  }

  .round-btn{
    width: 75px;
    height: 75px;
    border-radius: 35px;
  }

  .shadow1{
    -webkit-box-shadow: 1px 7px 12px -4px rgba(0,0,0,0.3);
-moz-box-shadow: 1px 7px 12px -4px rgba(0,0,0,0.3);
box-shadow: 1px 7px 12px -4px rgba(0,0,0,0.3);

  }


  .file-control{
    background-color: #e9e9e9;
    width:  100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    box-sizing: border-box;
    justify-content: flex-end;
    margin-bottom:  10px;
  }


  .file-control span{
    background-color: white;
    width:  35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    margin-left: 20px;
  }

  .file-control :hover{
    background-color: #b792a6;
    color : white;
    width:  35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    margin-left: 20px;
  }


  table{
      width: 100%;
      border-collapse: collapse;
      background-color:  white;
  }

  table tr, td{
    border: solid 1px #eaeaea;
  }

  table td{
    padding: .5em;
  }

  table tr:hover {
    background-color: green;
    transition: background .5s;
    cursor: pointer;
    color : white;
  }

  table tr:active {
    background-color: green;
    transition: background .5s;
    cursor: pointer;
    color: white;
  }


  .thumnail {
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: flex;
  }

  .text-preview{
    flex: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  }


  .double { text-overflow: ellipsis ellipsis; text-align: left; }

  .overflow {
    width: 500px;
    margin: 0 0 2em 0;
    font-size: .7em;
  
  /**
   * Required properties to achieve text-overflow
   */
      white-space: nowrap;
    overflow: hidden;
}

.selected{
  background: green;
  color : white
}